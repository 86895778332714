<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppHelpDesk.columns.category')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppHelpDesk.columns.category')"
                label-for="category"
              >
                <v-select
                  id="category"
                  v-model="ticketDataLocal.ticketCategoriaId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="texto"
                  :options="categories"
                  :reduce="(option) => option.id"
                  :disabled="!$can('write', 'MesaAyuda')"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('AppHelpDesk.titleError')"
              rules="required|max:256"
            >
              <b-form-group
                :label="$t('AppHelpDesk.titleError')"
                label-for="titleError"
              >
                <b-form-input
                  id="titleError"
                  v-model="ticketDataLocal.tituloError"
                  :disabled="!$can('write', 'MesaAyuda')"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('AppHelpDesk.problemDescription')"
              rules="required|max:1025"
            >
              <b-form-group
                :label="$t('AppHelpDesk.problemDescription')"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="ticketDataLocal.descripcion"
                  rows="3"
                  placeholder="La computadora no enciende, además de que se calienta mucho"
                  :disabled="!$can('write', 'MesaAyuda')"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('AppHelpDesk.solutionDescription')"
              :rules="(ticketDataLocal.ticketEstatusId === 5 ? 'required|' : '') + 'max:1025'"
            >
              <b-form-group
                :label="$t('AppHelpDesk.solutionDescription')"
                label-for="descriptionsolution"
              >
                <b-form-textarea
                  id="descriptionsolution"
                  v-model="ticketDataLocal.descripcionSolucion"
                  rows="3"
                  placeholder="Se hizo limpieza"
                  :disabled="!$can('write', 'MesaAyuda')"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="isSoftware">
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppHelpDesk.ipComputer')"
              rules="required|max:17|ipAddress"
            >
              <b-form-group
                :label="$t('AppHelpDesk.ipComputer')"
                label-for="ipComputer"
              >
                <b-form-input
                  id="ipComputer"
                  v-model="ticketDataLocal.ipEquipo"
                  :disabled="!$can('write', 'MesaAyuda')"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppUser.list.rolAuthor')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppUser.list.rolAuthor')"
                label-for="nombreUsuarioReporta"
              >
                <v-select
                  id="nombreUsuarioReporta"
                  v-model="ticketDataLocal.nombreUsuarioReporta"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  label="name"
                  :options="users"
                  :reduce="(option) => option.userName"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppHelpDesk.columns.assigned')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppHelpDesk.columns.assigned')"
                label-for="nombreUsuarioAsignado"
              >
                <v-select
                  id="nombreUsuarioAsignado"
                  v-model="ticketDataLocal.nombreUsuarioAsignado"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  label="texto"
                  :options="helpDeskUsers"
                  :reduce="(option) => option.id"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppHelpDesk.columns.status')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppHelpDesk.columns.status')"
                label-for="status"
              >
                <v-select
                  id="status"
                  v-model="ticketDataLocal.ticketEstatusId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="texto"
                  :options="status"
                  :reduce="(option) => option.id"
                  :disabled="!$can('write', 'MesaAyuda')"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppHelpDesk.columns.priority')"
              label-for="priority"
            >
              <v-select
                id="priority"
                v-model="ticketDataLocal.ticketPrioridadId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="priorities"
                :reduce="(option) => option.id"
                :disabled="!$can('write', 'MesaAyuda')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="$can('write', 'MesaAyuda')"
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppHelpDesk.userError')"
              label-for="userError"
            >
              <b-form-checkbox
                id="userError"
                v-model="ticketDataLocal.errorUsuario"
                name="userError"
                :value="true"
                :unchecked-value="false"
              >
                {{ $t("AppHelpDesk.userError") }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="$can('write', 'MesaAyuda')"
          class="float-right mt-2 mb-2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner
              v-if="addingTicket"
              small
              class="mr-1"
            />
            {{ $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormTextarea,
  BFormCheckbox,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required, max,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, computed } from '@vue/composition-api'
import optionService from '@/services/option.service'
import helpDeskService from '@/services/helpDesk.service'
import useradminService from '@/services/useradmin.service'

export default {
  components: {
    BFormCheckbox,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BSpinner,
    BFormInvalidFeedback,
    BFormInput,

    vSelect,

    ValidationObserver,
    ValidationProvider,
  },
  props: {
    ticketData: {
      type: Object,
      require: true,
      default() {
        return {
          ticketId: 0,
        }
      },
    },
  },
  data() {
    return {
      max,
      required,
    }
  },
  setup(props, { emit }) {
    const {
      fetchOptions,
    } = optionService()

    const {
      updateTicket,
    } = helpDeskService()

    const categories = ref([])
    const users = ref([])
    const helpDeskUsers = ref([])
    const status = ref([])
    const priorities = ref([])
    const addingTicket = ref(false)

    const ticketDataLocal = ref(JSON.parse(JSON.stringify(props.ticketData)))
    const foundCategory = ref({
      id: null,
      texto: '',
      textoAlterno: 0,
    })
    const resetTicketData = () => {
      ticketDataLocal.value = JSON.parse(JSON.stringify(props.ticketData))
    }

    const {
      fetchUsers,
    } = useradminService()

    const {
      fetchTextOptions,
    } = optionService()

    fetchOptions({ nombreDominio: 'EstatusTicket' }, data => {
      status.value = data
    })

    fetchUsers({ limit: 50 }, data => {
      users.value = data.collection
    })

    fetchOptions({ nombreDominio: 'CategoriaTicket' }, data => {
      categories.value = data
    })

    fetchOptions({ nombreDominio: 'PrioridadTicket' }, data => {
      priorities.value = data
    })

    fetchTextOptions({ nombreDominio: 'MesaAyudaUsuarios' }, data => {
      helpDeskUsers.value = data
    })

    const {
      refFormObserver,
      resetForm,
      getValidationState,
    } = formValidation(resetTicketData)

    const onSubmit = () => {
      addingTicket.value = true
      updateTicket(ticketDataLocal.value)
        .then(() => {
          addingTicket.value = false
          emit('refresh-data')
        })
        .catch(() => {
          addingTicket.value = false
        })
    }

    const isSoftware = computed(() => {
      const softwareCategory = categories.value.find(category => category.texto === 'Software')
      if (softwareCategory !== null && softwareCategory !== undefined) {
        if (ticketDataLocal.value.ticketCategoriaId === softwareCategory.id) {
          return true
        }
        let isChild = false
        foundCategory.value = categories.value.find(category => category.id === ticketDataLocal.value.ticketCategoriaId)
        while (!isChild && foundCategory.value !== null && foundCategory.value !== undefined) {
          if (Number(foundCategory.value.textoAlterno) === softwareCategory.id || Number(foundCategory.value.id) === softwareCategory.id) {
            isChild = true
          }
          foundCategory.value = categories.value.find(category => category.id === Number(foundCategory.value.textoAlterno))
        }
        return isChild
      }
      return false
    })

    return {
      resetTicketData,
      ticketDataLocal,
      resetForm,
      refFormObserver,
      categories,
      users,
      status,
      priorities,
      getValidationState,
      addingTicket,
      onSubmit,
      isSoftware,
      helpDeskUsers,
    }
  },
}
</script>
