<template>
  <b-card>
    <b-alert
      variant="danger"
      :show="ticketData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t("AppHelpDesk.fetchError") }}
      </h4>
    </b-alert>
    <b-tabs
      v-if="ticketData"
      pills
    >
      <b-tab
        v-if="ticketData.conversacionId"
        active
      >
        <template #title>
          <feather-icon
            icon="MessageSquareIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{
            $t("Conversation.conversation")
          }}</span>
        </template>
        <ticket-edit-tab-conversation
          :conversation-id="ticketData.conversacionId"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="TagIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t("AppHelpDesk.ticket") }}</span>
        </template>
        <ticket-edit-tab-ticket
          :ticket-data="ticketData"
          class="mt-2 pt-75"
          @refresh-data="
            () => {
              refTicketLog.getTicketLog();
            }
          "
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="BookOpenIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{
            $t("AppHelpDesk.logbook")
          }}</span>
        </template>
        <ticket-edit-tab-log
          ref="refTicketLog"
          :ticket-id="ticketData.ticketId"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="HelpCircleIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{
            $t("AppHelpDesk.solutions")
          }}</span>
        </template>
        <ticket-edit-tab-solutions :ticket-id="ticketData.ticketId" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import {
  BCard, BAlert, BTabs, BTab,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import ticketService from '@/services/helpDesk.service'
import TicketEditTabLog from './TicketEditTabLog.vue'
import TicketEditTabTicket from './TicketEditTabTicket.vue'
import TicketEditTabSolutions from './TicketEditTabSolutions.vue'
import TicketEditTabConversation from './TicketEditTabConversation.vue'

export default {
  components: {
    BCard,
    BAlert,
    BTabs,
    BTab,
    TicketEditTabLog,
    TicketEditTabTicket,
    TicketEditTabSolutions,
    TicketEditTabConversation,
  },
  setup() {
    const refTicketLog = ref(null)
    const ticketData = ref(null)
    const { fetchTicket } = ticketService()
    fetchTicket(router.currentRoute.params.ticketId, data => {
      ticketData.value = data
    })

    return {
      refTicketLog,
      ticketData,
    }
  },
}
</script>
