<template>
  <div>
    <conversation ref="refConversation" />
  </div>
</template>

<script>
import Conversation from '@/components/Conversation.vue'
import { ref, onMounted } from '@vue/composition-api'

export default {
  components: {
    Conversation,
  },
  props: {
    conversationId: {
      type: Number,
      require: true,
      default: 0,
    },
  },
  setup(props) {
    const refConversation = ref(null)
    onMounted(() => {
      refConversation.value.refetchData(props.conversationId)
    })
    return {
      refConversation,
      Conversation,
    }
  },
}

</script>
