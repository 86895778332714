<template>
  <div id="accordion">
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col
            cols="12"
            md="10"
          >
            <form-input
              v-model="query"
              :label="$t('AppHelpDesk.titleError')"
              validation-rules=""
              :get-validation-state="getValidationState"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              class="mt-2"
              type="submit"
            >
              <b-spinner
                v-if="searching"
                small
                class="mr-1"
              />
              {{ $t("AppHelpDesk.search") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-card
      v-for="(solve, index_s) in solves"
      :key="`solves_${index_s}`"
      no-body
      class="mb-1"
    >
      <b-card-header
        header-tag="header"
        class="p-1"
        role="tab"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          block
          @click="activeSolve = solve.ticketId"
        >
          {{ solve.tituloError }}
        </b-button>
      </b-card-header>
      <b-collapse
        :id="`accordion-${index_s}`"
        :visible="activeSolve == solve.ticketId"
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body>
          <h3>{{ $t("AppHelpDesk.problemDescription") }}</h3>
          <b-card-text>
            {{ solve.descripcion }}
          </b-card-text>
          <h3>{{ $t("AppHelpDesk.solutionDescription") }}</h3>
          <b-card-text>
            {{ solve.descripcionSolucion }}
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BButton,
  BCollapse,
  BCardBody,
  BCardText,
  BForm,
  BSpinner,
} from 'bootstrap-vue'
import FormInput from '@/components/FormInput.vue'
import { ValidationObserver } from 'vee-validate'
import helpDeskService from '@/services/helpDesk.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BButton,
    BCollapse,
    BCardBody,
    BCardText,
    BForm,
    BSpinner,

    FormInput,

    ValidationObserver,
  },
  setup() {
    const solves = ref([])
    const activeSolve = ref(null)
    const query = ref(null)
    const searching = ref(false)
    const resetQuery = () => {
      query.value = ''
    }
    const {
      fetchSolvesTickets,
    } = helpDeskService()
    fetchSolvesTickets(router.currentRoute.params.ticketId, { description: query.value }, data => {
      solves.value = data.tickets
    })
    const {
      getValidationState,
    } = formValidation(resetQuery)

    const onSubmit = () => {
      fetchSolvesTickets(router.currentRoute.params.ticketId, { description: query.value }, data => {
        solves.value = data.tickets
      })
    }

    return {
      solves,
      activeSolve,
      searching,
      query,
      getValidationState,
      fetchSolvesTickets,
      onSubmit,
    }
  },
}
</script>
